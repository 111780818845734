import type * as TypeformEmbed from '@typeform/embed'
import { useCallback, useEffect, useState } from 'react'

export enum TYPEFORMS {
  BHS = 'YJpJTYKl',
  BNA = 'FWDQZmG9',
}

export const useTypeform = (formId: TYPEFORMS) => {
  const [typeForm, setTypeForm] = useState<TypeformEmbed.Slider>()

  useEffect(() => {
    if (!formId) return

    import('@typeform/embed').then((mod) => {
      const typeForm = mod.createSlider(formId, {
        position: 'right',
      })
      setTypeForm(typeForm)
    })
  }, [formId])

  const handleTypeForm = useCallback(() => {
    typeForm && typeForm.open()
  }, [typeForm])

  return [handleTypeForm]
}
