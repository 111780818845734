import { defineDocument, defineObject } from 'sanity-typed-queries'
import { asset } from './asset'
import { JsonType } from './JsonType'
import { learnHubAuthor } from './learnHubAuthor'
import { learnHubCategory } from './learnHubCategory'
import type { SectionTypeStub } from './section'
import { ReferenceType } from './ReferenceType'

export type ShallowArticleTypeStub = {
  _type: 'article'
  _id: string
  _rev: string
  fields: {
    title?: string
    urlHandle?: string
    category?: ReferenceType | typeof learnHubCategory.learnHubCategory
    publishedDate?: string
    author?: ReferenceType | typeof learnHubAuthor.learnHubAuthor
    featuredImage?: ReferenceType | typeof asset.asset
    summary?: string
    content?: JsonType
    metaTitle?: string
    metaImage?: ReferenceType | typeof asset.asset
    oldUrlPaths?: string[]
  }
}

export interface ArticleTypeStub extends ShallowArticleTypeStub {
  fields: ShallowArticleTypeStub['fields'] & {
    components?: ReferenceType[]
  }
}

const articleFields = defineObject(
  'articleFields',
  {
    title: { type: 'string' },
    urlHandle: { type: 'string' },
    category: {
      type: 'reference',
      to: [{ type: 'learnHubCategory' }],
    },
    publishedDate: {
      type: 'string',
    },
    author: {
      type: 'reference',
      to: [{ type: 'learnHubAuthor' }],
    },
    featuredImage: {
      type: 'reference',
      to: [{ type: 'asset' }],
    },
    summary: {
      type: 'string',
    },
    content: {
      type: 'json',
    },
    components: {
      type: 'array',
      of: [
        {
          type: 'reference',
          to: [],
        },
      ],
    },
    metaTitle: {
      type: 'string',
    },
    metaImage: {
      type: 'reference',
      to: [{ type: 'asset' }],
    },
    oldUrlPaths: {
      type: 'array',
      of: [{ type: 'string' }],
    },
  },
  [
    {} as JsonType,
    {} as SectionTypeStub,
    asset.asset,
    learnHubCategory.learnHubCategory,
    learnHubAuthor.learnHubAuthor,
  ]
)

export const article = defineDocument(
  'article',
  {
    fields: {
      type: 'articleFields',
    },
  },
  [articleFields.articleFields]
)
