import { IconNames } from '@heights/heights-icons'
import { defineDocument, defineObject } from 'sanity-typed-queries'
import { asset } from './asset'

const learnHubCategoryFields = defineObject(
  'learnHubCategoryFields',
  {
    title: { type: 'string' },
    urlHandle: { type: 'string' },
    metaTitle: { type: 'string' },
    description: { type: 'string' },
    icon: {
      type: 'string',
      options: { list: Object.values(IconNames) },
    },
    colour: { type: 'string' },
    metaImage: { type: 'reference', to: [{ type: 'asset' }] },
  },
  [asset.asset]
)

export const learnHubCategory = defineDocument(
  'learnHubCategory',
  {
    fields: {
      type: 'learnHubCategoryFields',
    },
  },
  [learnHubCategoryFields.learnHubCategoryFields]
)
