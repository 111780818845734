import { Document } from '@contentful/rich-text-types'
import readingTime from 'reading-time'
import { documentToPlainTextString } from '@contentful/rich-text-plain-text-renderer'

const MS_PER_CHAR = 51.65

export function getReadingTime(docOrCharLength: Document | number) {
  if (typeof docOrCharLength === 'number') {
    const time = docOrCharLength * MS_PER_CHAR
    return {
      time,
      minutes: time / 60 / 1000,
      text: `${Math.ceil(time / 60 / 1000)} min read`,
    }
  }

  const text = documentToPlainTextString(docOrCharLength)
  return readingTime(text)
}
