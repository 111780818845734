export const ensureInternalUrlTrailingSlash = (url: string): string => {
  try {
    if (!url) return ''

    if (url.startsWith('/')) {
      if (url.endsWith('/')) return ''
      const newUrl = url + '/'
      return newUrl
    }

    const urlData = new URL(url)
    let { protocol, pathname, hostname } = urlData
    const { search, hash } = urlData

    if (protocol === 'mailto:') return ''

    if (process.env.SITE_URL && process.env.SITE_URL.includes(hostname)) {
      hostname = ''
      protocol = ''
      if (pathname && !pathname.endsWith('/')) {
        pathname += '/'
      }
      const newUrl = `${
        protocol ? protocol + '//' : ''
      }${hostname}${pathname}${search}${hash}`
      return newUrl
    }
    return url
  } catch (e) {
    return url
  }
}
