import { useMemo } from 'react'
import { useCtaAction } from './useCtaAction'
import { TCta } from '@heights/heights-react-hooks'
import compact from 'just-compact'
import { ctaAction } from '@/schemas/ctaAction'

const [, ctaActionType] = ctaAction.builder.pick(['fields']).first().use()

type Props<Multiple extends boolean = false> = {
  ctaType?: 'link' | 'button'
  ctaText?: string
  ctaLink?: string
  mutate?: (cta: TCta) => TCta
} & (Multiple extends true
  ? {
      ctaAction?: null
      ctaActions?: typeof ctaActionType[] | null
    }
  : {
      ctaAction?: typeof ctaActionType | null
      ctaActions?: null
    })

export function useOldCtaAction<Multiple extends false>(
  props: Props<Multiple>
): TCta | null
export function useOldCtaAction<Multiple extends true>(
  props: Props<Multiple>
): TCta[] | null
export function useOldCtaAction<Multiple extends boolean = false>({
  ctaAction,
  ctaActions,
  ctaType,
  ctaText,
  ctaLink,
  mutate,
}: Props<Multiple>): TCta | TCta[] | null {
  const resolvedCtaActions = useCtaAction(compact(ctaActions ?? [ctaAction]))

  const transformedActions = useMemo(() => {
    const actions = compact(
      compact(resolvedCtaActions ?? []).map<TCta | null>((action) => {
        if (action) return action
        if (ctaText) {
          return {
            style: ctaType === 'button' ? 'primary' : 'tertiary',
            label: ctaText,
            href: ctaLink,
          }
        }
        return null
      })
    )

    return mutate ? actions.map(mutate) : actions
  }, [ctaText, ctaType, ctaLink, mutate, resolvedCtaActions])

  if (ctaActions) return transformedActions
  return transformedActions?.[0] ?? null
}
