import React, { FC } from 'react'
import { Box, Grid, ThemeUIStyleObject, Text } from 'theme-ui'
import { Image, ImageProps } from '@heights/heights-ui-library'
import { useShowDatesAsUTC } from '@/hooks'
import { dayjs } from '@/utils'
import { twMerge } from '@heights/heights-theme'

interface BylineProps {
  authorName: string
  roleTitle: string
  urlHandle?: string
  publishDate: string
  timeToRead: string
  sx?: ThemeUIStyleObject
  authorImage?: ImageProps
}

export const Byline: FC<BylineProps> = ({
  publishDate,
  timeToRead,
  sx,
  authorName,
  authorImage,
  roleTitle,
}) => {
  const datesAsUtc = useShowDatesAsUTC()

  return (
    <div
      className={twMerge(
        'grid gap-3 border-b py-3 border-neutral-500 text-4 items-center',
        authorImage && 'grid-cols-[48px_1fr_1fr]',
        !authorImage && 'grid-cols-2'
      )}
    >
      {authorImage && (
        <div className="overflow-hidden rounded-full w-8 h-8">
          <Image {...authorImage} className="block" alt={authorName} />
        </div>
      )}
      <Box>
        <div className="font-medium">{authorName}</div>
        {!!roleTitle && (
          <div className="font-normal text-neutral-600">{roleTitle}</div>
        )}
      </Box>
      <div className="text-right">
        <div className="font-medium">
          {(datesAsUtc ? dayjs(publishDate).utc() : dayjs(publishDate)).format(
            'MMMM D, YYYY'
          )}
        </div>
        <div className="text-neutral-600">{timeToRead}</div>
      </div>
    </div>
  )
}
