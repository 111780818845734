import { defineDocument, defineObject } from 'sanity-typed-queries'
import { asset } from './asset'

const learnHubAuthorFields = defineObject(
  'learnHubAuthorFields',
  {
    profileImage: {
      type: 'reference',
      to: [{ type: 'asset' }],
    },
    name: { type: 'string' },
    roleTitle: { type: 'string' },
    urlHandle: { type: 'string' },
  },
  [asset.asset]
)

export const learnHubAuthor = defineDocument(
  'learnHubAuthor',
  {
    fields: {
      type: 'learnHubAuthorFields',
    },
  },
  [learnHubAuthorFields.learnHubAuthorFields]
)
